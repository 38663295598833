@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kodchasan:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
* {
  font-family: "Kodchasan", "sans-serif";
  /* font-size:16px */
}

h1 {
  @apply text-2xl font-extrabold mt-0; /*leading-6**/
}

h2 {
  @apply text-xl font-extrabold my-0; /*leading-[39px]*/
}

h3 {
  @apply text-lg font-bold my-0; /*leading-[38px]*/
}

h4 {
  @apply text-base font-bold my-0;
}

h5 {
  @apply text-base; /*leading-[35px]*/
}

html {
  font-family: "Sen", "sans-serif";
}

body {
  background-color: #f0f2f5;
  font-family: "Sen", "sans-serif";
  color: #36505e;
}

.basic-card-small {
  @apply rounded-[10px] shadow-md py-[30px] px-[17px];
}

.basic-card {
  @apply rounded-[10px] shadow-md py-5 px-6;
}

.ql-editor {
  /* background-color: red !important; */
  font-family: "Sen", "sans-serif" !important;
  font-style: none !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
  height: 100% !important;
}

/* custom react quill */
.custom > .ql-toolbar {
  overflow-y: auto !important;
  border-radius: 10px 10px 0 0;
}

.custom.error > .ql-toolbar {
  overflow-y: auto !important;
  border-radius: 10px 10px 0 0;
  border: 1px solid #ff4d4f;
}

.custom > .ql-container {
  min-height: 10rem !important;
  border-radius: 0 0 10px 10px;
}

.custom.error > .ql-container {
  min-height: 10rem !important;
  border-radius: 0 0 10px 10px;
  border: 1px solid #ff4d4f;
}

.ql-container {
  height: 80% !important;
  overflow-y: auto !important;
}

.ql-editor p {
  @apply !mb-3;
}

.ql-editor h1 {
  @apply !mb-3 font-bold;
}

.ql-editor h2 {
  @apply !mb-3;
}

.admin-table .ant-table-thead .ant-table-cell {
  background-color: #d4f5c5 !important;
}

/* Bitmovin UI player changes */

/* Changing the watermark to skilledin logo. Get from public folder  */
.bmpui-ui-watermark {
  @apply !bg-[url("./assets/images/skilledin3.png")] !w-16 !h-16 !bg-contain !bg-no-repeat !bg-center;
}

.bmpui-ui-volumeslider .bmpui-seekbar-playbackposition {
  @apply !bg-primary;
}

.bmpui-ui-volumeslider .bmpui-seekbar-playbackposition-marker {
  @apply !bg-primary-bg;
}

.bmpui-seekbar-playbackposition-marker {
  @apply !bg-primary-bg !border-primary;
}

.golden-subtitle {
  @apply text-[10px] tracking-widest text-brown font-light uppercase;
}

.square-navigation .ant-tabs-nav-list {
  @apply w-full;
}

.square-navigation .ant-tabs-nav {
  @apply !sticky top-0 z-10;
}

.square-navigation .ant-tabs-tab {
  @apply flex-grow items-center w-full justify-center !border !border-darkGrey;
}

.square-navigation .ant-tabs-tab-active {
  @apply bg-primary-bg;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Makes a selectbox grey */
.bg-grey-select > .ant-select > .ant-select-selector {
  background-color: #d9d9d9 !important;
}

.full-width-upload .ant-upload.ant-upload-select {
  @apply !w-full !m-0;
}

.full-width-upload .ant-upload-list-item-container {
  @apply !w-full !m-0;
}

.ant-picker-ok > button:enabled {
  background: #86c769 !important;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

.shake {
  animation: shake 0.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.ql-picker-item[data-value="3"]::before {
  content: "Heading" !important;
}
.ql-picker-label[data-value="3"]::before {
  content: "Heading" !important;
}
